import type { Alert } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { AlertCountUpdateEvent } from '../../Alerts/AlertCountUpdateEvent';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { GlobalFlags } from '../../GlobalFlags';
import { Launcher } from '../../Launcher/Launcher';
import { LauncherEvent } from '../../Launcher/LauncherEvent';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import './support-bar.scss';

export class SupportBar {
    @bindable() onActionClicked: () => void;

    protected isMobile = GlobalFlags.isMobile;
    protected alertCount: number;
    protected account = ApplicationState.account;

    private _alertCountUpdateEventSub: Subscription;
    private _dataRefreshedEventSub: Subscription;

    protected isAdminOrOwner = ApplicationState.isInAnyRole(['Admin', 'Owner']);
    private _LauncherSubscription: any;

    protected attached() {
        const isOwnerAdminOrCreator = ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator']);
        this.alertCount = Data.count<Alert>('alerts', alert => {
            if (isOwnerAdminOrCreator) return true;

            if (RethinkDbAuthClient.session?.email && alert.audience?.includes(RethinkDbAuthClient.session.email)) return true;

            return false;
        });

        this._alertCountUpdateEventSub = AlertCountUpdateEvent.subscribe<AlertCountUpdateEvent>(event => (this.alertCount = event.count));
        this._dataRefreshedEventSub = DataRefreshedEvent.subscribe<DataRefreshedEvent>(e => {
            if (!e.hasAnyType('alerts')) return;
            this.setAlertCount();
        });

        this._LauncherSubscription = LauncherEvent.subscribe((event: LauncherEvent) => {
            this.launcherVisible = event.visible;
        });
    }

    protected detached() {
        this._alertCountUpdateEventSub?.dispose();
        this._dataRefreshedEventSub?.dispose();
        this._LauncherSubscription?.dispose();
    }

    protected getSupport() {
        this.onActionClicked();

        ApplicationState.getSupport('Squeegee App Support', true, "", "support-form.squeegee-support");
    }

    protected goToAlerts(): void {
        this.onActionClicked();
        ApplicationState.navigateTo('alerts');
    }

    protected goToQuickStart(): void {
        this.onActionClicked();
        ApplicationState.navigateTo('quickstart');
    }

    private setAlertCount = () => {
        this.alertCount = AlertCountUpdateEvent.getCount();
    };

    protected unverified = RethinkDbAuthClient.session?.unverified;

    protected trialing = (ApplicationState.subscription.status || '') === 'trial';

    protected toggleLauncher() {
        Launcher.toggle();
    }

    protected launcherVisible = false;
}
