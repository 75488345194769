import type { NotificationType, TranslationKey } from "@nexdynamic/squeegee-common";
import { CustomDialog } from "../Dialogs/CustomDialog";
import "./SampleMessageDialog.css";
export class SampleMessageDialog extends CustomDialog<boolean> {

    isEmail = false;


    constructor(public message: string, public notificationType: NotificationType, okLabel: TranslationKey = 'general.send') {
        super('sampleMessageDialog', '../Notifications/SampleMessageDialog.html', 'prompts.sample-message-title', {
            okLabel,
            cancelLabel: 'general.cancel',
            coverViewport: true,
            smallerOnDesktop: true,
            cssClass: 'sample-message-dialog',
        });

        this.isEmail = notificationType === 'Email';
    }
}