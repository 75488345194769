
import type { Customer } from '@nexdynamic/squeegee-common';
import { wait } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, BindingEngine, computedFrom, inject, TaskQueue } from 'aurelia-framework';
import { CustomerDialog } from '../Customers/Components/CustomerDialog';
import { Logger } from '../Logger';
import { Api } from '../Server/Api';
import { Utilities } from '../Utilities';
import type { IMessage } from './Message';
import './Messages.scss';
import type { IMessageGroup } from './MessageUtils';
import { MessageUtils } from './MessageUtils';

@inject(TaskQueue, BindingEngine)
export class Messages {
    @bindable() messages: Array<IMessage> = [];
    @bindable() lastReadMessageId?: string;
    @bindable() outgoing: boolean;
    @bindable() moreToLoad: boolean;
    @bindable() customer: Customer;
    @bindable loadMore?: () => Promise<void>;
    @bindable onScrollBottom?: ({ message }: { message: IMessage }) => void;
    @bindable onCustomise: () => void;
    @bindable onMessage: ({ message }: { message: IMessage }) => Promise<void>;
    @bindable hasContactMethods: boolean;

    private api = Api;
    @computedFrom('api.isConnected')
    protected get isOnline() {
        return this.api.isConnected;
    }

    protected messageGroups: Array<IMessageGroup>;

    protected messagesView: HTMLDivElement | undefined;

    protected loadingMore: boolean;
    /**
     * Using visibility style so we can caculate the scroll height then scroll down the view
     * So when the user loads the messages view it has automaticlly opened at the bottom of the messages
     */
    protected showMessages: boolean;

    constructor(private taskQueue: TaskQueue, private bindingEngine: BindingEngine) { }

    private messageSubscription: Subscription;



    private get isAtBottom() {
        return this.messagesView && this.messagesView.scrollHeight - this.messagesView.scrollTop === this.messagesView.clientHeight;
    }

    private get latestMsg() {
        return this.messages[this.messages.length - 1];
    }

    private handleScrollBottom() {
        if (this.onScrollBottom) this.onScrollBottom({ message: this.latestMsg });
    }

    private onScrollEnd = () => {
        if (this.isAtBottom && this.onScrollBottom) {
            Logger.info('Message view scroll ended and the view is at the bottom');
            this.handleScrollBottom();
        }
    };

    private onScrollEndDebounce = Utilities.debounce(this.onScrollEnd, 100);

    protected attached() {
        this.showMessages = false;
        this.messageGroups = MessageUtils.groupMessages(this.messages);
        this.messageSubscription = this.bindingEngine.collectionObserver(this.messages).subscribe(this.messagesChanged.bind(this));
        // Runs after the messages have rendered
        this.taskQueue.queueMicroTask({
            call: () => this.onMessagesLoaded(),
        });

        if (this.messagesView) this.messagesView.addEventListener('scroll', this.onScrollEndDebounce);

        window.addEventListener('resize', this.resizeDebounce);
    }

    protected detached() {
        if (this.messagesView) this.messagesView.removeEventListener('scroll', this.onScrollEndDebounce);
        window.removeEventListener('resize', this.resizeDebounce);
        this.messageGroups = [];
        if (this.messageSubscription) this.messageSubscription.dispose();
        this.showMessages = false;
        if (this._onMessagesLoadedTimeout) clearTimeout(this._onMessagesLoadedTimeout);
    }

    protected async openEditCustomerDialog() {
        return new CustomerDialog(this.customer).edit();
    }

    private messagesChanged() {
        if (this.messages) {
            // Todo if possible don't sort the whole array on a new item do partial update
            const wasAtBottom = this.isAtBottom;
            this.messageGroups = MessageUtils.groupMessages(this.messages);
            /*If the user was prevoiusly at the bottom of the messages view 
            before we loaded more than scroll them to the bottom of the new messages 
            */

            if (wasAtBottom) {
                requestAnimationFrame(() => {
                    this.scrollToBottom(true, false);
                });
            }
        } else {
            this.messageGroups = [];
        }
    }



    private _onMessagesLoadedTimeout: any;
    async onMessagesLoaded() {
        // If there are messages then ensure we fill the viewport
        if (this.messages.length) await this.ensureViewportFull();
        this._onMessagesLoadedTimeout = setTimeout(() => {
            //Hack due to aurelia view model and dialog not yet opened when this is fired
            this.scrollToBottom(false, Boolean(this.lastReadMessageId));
            this.showMessages = true;
        }, 150);
    }

    protected scrollToBottom(animate = true, targetLastRead = false) {
        // Ensure all elements have rendered before scrolling
        this.taskQueue.queueMicroTask({
            call: () => {
                if (this.messagesView) {
                    let target = null;

                    if (targetLastRead) {
                        target = this.getLastReadMessageElement();
                    } else {
                        const children = this.messagesView.querySelectorAll('.message-bubble');
                        target = children.item(children.length - 1);
                    }

                    if (target) target.scrollIntoView({ block: 'end', behavior: animate ? 'smooth' : 'auto' });
                }
            },
        });
    }

    private resizeEvent = async () => {
        await this.ensureViewportFull();
    };

    private resizeDebounce = Utilities.debounce(this.resizeEvent, 250);

    // Makes sure we load enough messages to fill viewport
    private ensureViewportFull = async () => {
        if (this.messagesView && this.loadMore) {
            // If scroll height is greater than the client height of messagesview then we know that content is larger than viewport and is thus full
            const contentIsFull = this.messagesView.scrollHeight > this.messagesView.clientHeight;
            const previousMessagesLength = this.messages.length;
            if (!contentIsFull) {
                await this.loadMore();
                // If more messages were loaded then recheck that viewport is full
                if (this.messages.length > previousMessagesLength) {
                    this.ensureViewportFull();
                } else if (!contentIsFull) {
                    /* If we don't have enough messages to fill viewport then fire the onScrollBottom 
                    because we have reached the bottom and need to update read messages 
                    */
                    this.handleScrollBottom();
                }
            }
        }
    };

    protected async newMessage(message: IMessage) {
        if (this.onMessage) await this.onMessage({ message });
    }

    protected async loadMoreMessages() {
        if (this.moreToLoad === false) return;
        this.loadingMore = true;
        // get the first message in the list so after we load more messages we can scroll back to that element
        const previousScrollHeight = this.messagesView?.scrollHeight;
        // This is artificial. in the future we might not be able to load from memory
        await wait(200);
        if (this.loadMore) await this.loadMore();
        // set the current scroll position back to what it was before we loaded more messages
        if (this.messagesView && previousScrollHeight) this.messagesView.scrollTop = this.messagesView.scrollHeight - previousScrollHeight;
        this.loadingMore = false;
    }

    protected getLastReadMessageElement() {
        if (this.lastReadMessageId && this.messagesView) {
            return this.messagesView.querySelector(`#message-id-${this.lastReadMessageId}`);
        }
    }

    protected getFirstMessageElement() {
        if (this.messagesView) {
            return this.messagesView.querySelector(`#message-id-${this.messages[0].id}`);
        }
    }

    @computedFrom('lastReadMessageId', 'messages')
    protected get unreadCount() {
        if (this.lastReadMessageId) {
            const lastReadMsgIndex = this.messages.findIndex(msg => msg.id === this.lastReadMessageId);

            return this.messages.length - 1 - lastReadMsgIndex;
        } else return 0;
    }
}
