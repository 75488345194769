import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import ExternalTemplateDialog from '../../Dialogs/ExternalContent/ExternalTemplateDialog';
import type { IMessage } from '../../Messages/Message';
import type { EmailSnippet, TemplateSnippet } from '../../Messages/Snippet';
import './message-bubble-snippet.scss';

export class MessageBubbleSnippet {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) protected message: IMessage<TemplateSnippet<unknown>>;

    protected async view() {
        if (this.message && this.message.snippet && this.message.type === 'Email') {
            const snippet = this.message.snippet as EmailSnippet;
            const dialog = new ExternalTemplateDialog(
                snippet.templateName,
                { model: snippet.model, dangerouslySetHTML: true },
                this.message.content
            );
            await dialog.show();
        }
    }

    @computedFrom('message.snippet.type')
    get cssClasses() {
        if (this.message && this.message.snippet && this.message.snippet.type) {
            return `message-bubble-snippet--${this.message.snippet.type}`;
        }
        return;
    }
}
