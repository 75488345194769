import type { Customer, Notification, NotificationStatus } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { UserService } from '../Users/UserService';
import type { IMessage, MessageTag } from './Message';
import { MessageStatus } from './Message';

export interface IMessageGroup {
    date?: string;
    messages: Array<IMessage>;
}

export const enum MessageGroupByIsoOffset {
    DAY = 10,
}

export class MessageUtils {
    public static getIconAndColourFromStatus(status: MessageStatus): { icon: string; colour: string } {
        switch (status) {
            case MessageStatus.PENDING:
                return { icon: 'schedule', colour: '#0288d1' };
            case MessageStatus.SENT:
                return { icon: 'email', colour: '#0288d1' };
            case MessageStatus.DELIVERED:
                return { icon: 'mark_email_read', colour: '#66b988' };
            case MessageStatus.OPENED:
                return { icon: 'drafts', colour: '#66b988' };
            case MessageStatus.READ:
                return { icon: 'library_books', colour: '#66b988' };
            case MessageStatus.FAILED:
                return { icon: 'error', colour: '#ff4081' };
        }
        return { icon: 'help', colour: '#0288d1' };
    }
    public static groupMessages(
        messages: Array<IMessage>,
        groupBy: MessageGroupByIsoOffset = MessageGroupByIsoOffset.DAY
    ): Array<IMessageGroup> {
        const messageGroups = messages.reduce((groups, message) => {
            const date = message.sent.substring(0, groupBy);
            const group = groups[date];
            if (group) group.messages.push(message);
            else groups[date] = { date: date, messages: [message] };

            return groups;
        }, {} as { [key: string]: IMessageGroup });

        return Object.keys(messageGroups).map(key => {
            return messageGroups[key];
        });
    }

    public static getContentFromNotification(notification: Notification): string {
        const { message, description } = notification;
        if (message) {
            if (typeof message === 'string') {
                return message;
            } else if (message.content && typeof message.content === 'string') {
                return message.content;
            } else if (message.title) {
                return `${message.title || ''} ${message.subtitle || ''}`.trim();
            }
        }

        return description;
    }

    public static notificationStatusToMessageStatus(status: NotificationStatus): MessageStatus {
        switch (status) {
            case 'pending':
            case 'sending':
                return MessageStatus.PENDING;
            case 'sent':
            case 'processed':
                return MessageStatus.SENT;
            case 'delivered':
                return MessageStatus.DELIVERED;
            case 'open':
                return MessageStatus.OPENED;
            case 'click':
                return MessageStatus.READ;
            case 'deferred':
            case 'bounce':
            case 'dropped':
            case 'failed':
                return MessageStatus.FAILED;
            default: {
                return MessageStatus.UNKNOWN;
            }
        }
    }

    public static getMessageSender(isReply?: boolean, customerIdOrUserEmail?: string): string {
        if (isReply) {
            const customer = (customerIdOrUserEmail && Data.get<Customer>(customerIdOrUserEmail)) || undefined;
            return ApplicationState.localise('messages.replied-from', { sender: customer?.name || 'customer' });
        } else {
            const user = UserService.getUser(customerIdOrUserEmail);
            const { businessName } = ApplicationState.account;

            let sender = ApplicationState.localise('messages.sender', { user: user?.name || user?.email || 'user' });
            if (businessName) sender += ` (${businessName})`;

            return sender;
        }
    }

    public static getDeliveryTagsFromNotification(
        { statusHistory }: Notification,
        dateFormat = 'MMM D HH:mm',
        detailed = false
    ): Array<MessageTag> {
        const tags: Array<MessageTag> = [];

        if (statusHistory?.length) {
            // WTF Notification status to timestamp removes dupes and only uses the oldest time stamp due to legacy data having duped values
            const statusMap = {} as Record<MessageStatus, number>;

            for (const history of statusHistory) {
                const status = MessageUtils.notificationStatusToMessageStatus(history.status);
                const previousTimestamp = statusMap[status];
                if (previousTimestamp === undefined) {
                    statusMap[status] = history.timestamp;
                    // If there is already a matching status check if this status is older if it is use that instead
                } else if (previousTimestamp > history.timestamp) statusMap[status] = history.timestamp;
            }

            for (const status in statusMap) {
                const text = statusMap[status as MessageStatus] ? moment(statusMap[status as MessageStatus]).format(dateFormat) : '';
                switch (status as MessageStatus) {
                    case 'sent':
                        tags.push({ icon: 'send', color: '#4e70a0', text: detailed ? 'sent ' + text : text });
                        continue;
                    case 'failed':
                        tags.push({ icon: 'error_outline', color: '#f66578', text: detailed ? 'failed ' + text : text });
                        continue;
                    case 'delivered':
                        //WTF don't show delivered if they have read it
                        if (!statusMap['read'] && !statusMap['opened'])
                            tags.push({ icon: 'local_shipping', color: '#0288d1', text: detailed ? 'delivered ' + text : text });
                        continue;
                    case 'read':
                        tags.push({ icon: 'mark_email_read', color: '#68bb8a', text: detailed ? 'read ' + text : text });
                        continue;
                    case 'opened':
                        tags.push({ icon: 'drafts', color: '#68bb8a', text: detailed ? 'clicked ' + text : text });
                        continue;
                }
            }
        }

        return tags;
    }
}
