import type { Notification, NotificationType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { MessageUtils } from './MessageUtils';
import type { Snippet } from './Snippet';
import { EmailSnippet } from './Snippet';

export type MessageType = NotificationType;

export const enum MessageStatus {
    PENDING = 'pending',
    SENT = 'sent',
    DELIVERED = 'delivered',
    READ = 'read',
    OPENED = 'opened',
    FAILED = 'failed',
    UNKNOWN = 'Unknown',
}

export interface INewMessage {
    content: string;
}

export type MessageTag = {
    icon: string;
    color: string;
    text: string;
};

export interface IMessage<SnippetType = unknown> {
    id: string;
    type: MessageType;
    sender: string;
    outgoing: boolean;
    content: string;
    status: MessageStatus;
    statusTime: number;
    sent: string;
    snippet?: SnippetType;
    tags?: Array<MessageTag>;
    customerId?: string;
    unread: boolean;
    pending: boolean;
    historySummary: Array<string>;
}

export class Message implements IMessage {
    id: string;
    type: MessageType;
    sender: string;
    outgoing: boolean;
    content: string;
    createdDate: string;
    status: MessageStatus;
    statusTime: number;
    sent: string;
    snippet?: Snippet;
    failed = false;
    tags: Array<MessageTag>;
    statusColour: string;
    statusIcon: string;
    unread = false;
    customerId?: string;
    isScheduled?: string;
    pending = false;
    historySummary: Array<string> = [];

    static fromNotification(notification: Notification): Message {
        const message = new Message();
        message.customerId = notification.customerId;
        message.id = notification._id;
        message.type = notification.type;
        const isReply = notification.type === 'SMSReply' || notification.type === 'ChatReply';
        message.sender = MessageUtils.getMessageSender(isReply, isReply ? notification.customerId : notification.createdBy);
        message.outgoing = true;
        message.status = MessageUtils.notificationStatusToMessageStatus(notification.status);
        message.statusTime = notification.timestamp;
        const iconAndColour = MessageUtils.getIconAndColourFromStatus(message.status);
        message.statusColour = iconAndColour.colour;
        message.statusIcon = iconAndColour.icon;
        message.sent = notification.sent || notification.createdDate;
        message.createdDate = notification.createdDate;
        message.failed = message.status === MessageStatus.FAILED;
        message.content = MessageUtils.getContentFromNotification(notification);
        message.unread = Boolean(notification.unread);
        message.pending = notification.status === 'pending';
        switch (notification.type) {
            case 'Email':
                message.content = ApplicationState.localise('messages.email-to', { to: notification.address });
                message.snippet = new EmailSnippet(notification);
                message.tags = MessageUtils.getDeliveryTagsFromNotification(notification);
                break;
            case 'SMSReply':
                // AS long as the api hasn't changed these fields should be set and always be a string
                message.content =
                    typeof notification.message === 'string'
                        ? notification.message
                        : ApplicationState.localise('messages.unable-to-display-message-content');
                message.outgoing = false;
                break;
            case 'ChatReply':
                message.outgoing = false;
                break;
            default:
                break;
        }
        message.isScheduled =
            !!notification.sendAtSecondsTimestamp && notification.sendAtSecondsTimestamp > Date.now() / 1000
                ? moment(notification.sendAtSecondsTimestamp * 1000).format('DD/MM/YYYY HH:mm')
                : undefined;

        for (const h of (notification.statusHistory || [])
            .filter(h => h.status !== 'sent' && h.status !== 'sending' && h.data && h.status && h.timestamp)
            .sort((x, y) => x.timestamp - y.timestamp)) {
            message.historySummary.push(`${moment(h.timestamp).format('lll')} ${h.status} (${h.data})`);
        }

        return message;
    }
}
